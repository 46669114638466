import React, { useEffect, useState, useRef } from "react";

import { SketchPicker } from "react-color";
import { defineQuery, hasComponent, addComponent, removeComponent, removeEntity } from "bitecs";
import { Text } from "../../../../../bit-components";

import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

import { toast } from "react-hot-toast";

export default function TextMenu({ object, setObject, scene }) {
    const world = APP.world;

    const [showPicker, setShowPicker] = useState(false);
    const [textValue, setTextValue] = useState(APP.getString(Text.value[object.eid]));
    const [textMaxWidth, setTextMaxWidth] = useState(APP.getString(Text.maxWidth[object.eid]));
    const [textColor, setTextColor] = useState(APP.getString(Text.color[object.eid]));
    const [textAlign, setTextAlign] = useState(APP.getString(Text.textAlign[object.eid]));
    const pickerRef = useRef(null);

    const changeText = e => {
        setTextValue(e.target.value);
        Text.value[object.eid] = APP.getSid(e.target.value);
        Text.needsUpdate[object.eid] = 1;

        window.APP.objectHelper.change(object.eid);
    };

    const handleTextColorChange = color => {
        setTextColor(color.hex);
        Text.color[object.eid] = APP.getSid(color.hex);
        Text.needsUpdate[object.eid] = 1;

        window.APP.objectHelper.change(object.eid);
    };

    const handleAlignmentChange = alignment => {
        setTextAlign(alignment);
        Text.textAlign[object.eid] = APP.getSid(alignment);
        Text.needsUpdate[object.eid] = 1;

        window.APP.objectHelper.change(object.eid);
    };

    const handleTextMaxWidthChange = width => {
        setTextMaxWidth(width);
        Text.maxWidth[object.eid] = APP.getSid(width);
        Text.needsUpdate[object.eid] = 1;

        window.APP.objectHelper.change(object.eid);
    };

    const handleClickOutside = (event) => {
        if (pickerRef.current && !pickerRef.current.contains(event.target)) {
            setShowPicker(false);
        }
    };

    useEffect(() => {
        if (showPicker) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showPicker]);

    return (
        <Col xs={12} className="mb-3">
            <textarea
                id="troika-value-input"
                className="mt-2 form-control"
                style={{ height: "79px" }}
                type="text"
                value={textValue}
                onChange={e => changeText(e)}
            />
            <Row>
                <Col>
                    <button
                        type="button"
                        className="p-3 mt-2 form-control"
                        style={{
                            backgroundColor: textColor,
                            width: "100%",
                            height: "20px",
                            border: "1px solid #ccc"
                        }}
                        onClick={() => setShowPicker(!showPicker)}
                    />
                                    {showPicker && (
                        <>
                            <div 
                                ref={pickerRef}
                                style={{
                                    position: "relative",
                                    top: "900%",
                                }}
                            >
                                <SketchPicker color={textColor} onChange={handleTextColorChange} />
                            </div>
                        </>
                    )}
                </Col>
                <Col>
                    <ButtonGroup className="mt-2">
                        <Button variant="purple-dark" onClick={() => handleAlignmentChange("left")}>
                            <i className="bi bi-text-left"></i>
                        </Button>
                        <Button variant="purple-dark" onClick={() => handleAlignmentChange("center")}>
                            <i className="bi bi-text-center"></i>
                        </Button>
                        <Button variant="purple-dark" onClick={() => handleAlignmentChange("right")}>
                            <i className="bi bi-text-right"></i>
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label>Max Width</Form.Label>
                    <Form.Range max="3" min="0.2" step="0.1" onChange={e => handleTextMaxWidthChange(e.target.value)} />
                </Col>
            </Row>
        </Col>
    );
}
