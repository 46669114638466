import React, { useEffect, useState, useRef } from "react";

import { SketchPicker } from "react-color";
import { defineQuery, hasComponent, addComponent, removeComponent, removeEntity } from "bitecs";
import {
    MediaFrame,
    MediaLoader,
    MediaPDF,
    IsLocalOnly,
    MediaImage,
    Link,
    Locked,
    Billboard,
    TextUpdated,
    Text,
    Owner,
    Question,
    StudentsCanMove,
    LiveFeed,
    MediaAudio,
    Equirectangular,
    PDFSettingsChanged,
    YouTube,
    SpinningAnimation
} from "../../../../bit-components";

import { createMessageDatas } from "../../../../bit/systems/networking";
import { createNetworkedEntity } from "../../../../utils/create-networked-entity";
import { setMatrixWorld } from "../../../../utils/three-utils";

import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import Row from "react-bootstrap/Row";

import { toast } from "react-hot-toast";

const _vec1 = new THREE.Vector3();
const _vec2 = new THREE.Vector3();
const _mat4 = new THREE.Matrix4();

export default function Other({ object, world, scene, closePopover }) {
    const [isInspecting, setIsInspecting] = useState(false);

    const cloneObject = () => {
        // Cloning by creating a networked entity from initial data.
        // Probably it would be easier than copying Component data and
        // Object3D.
        let { prefabName, initialData } = createMessageDatas.get(object.eid);
        console.log(prefabName, initialData);
        if (prefabName === "text") {
            initialData = {
                value: APP.getString(Text.value[object.eid]),
                fontSize: 0.2,
                maxWidth: APP.getString(Text.maxWidth[object.eid]),
                textAlign: APP.getString(Text.textAlign[object.eid]),
                color: APP.getString(Text.color[object.eid])
            };
        }

        if (prefabName === "youtube") {
            initialData.link = APP.getString(YouTube.src[object.eid]);
        }

        const clonedEid = createNetworkedEntity(world, prefabName, initialData);
        const clonedObj = world.eid2obj.get(clonedEid);

        // Place the cloned object a little bit closer to the camera in the scene
        // TODO: Remove the dependency with AFRAME
        const camera = scene.systems["hubs-systems"].cameraSystem.viewingCamera;
        camera.updateMatrices();

        const sourceObj = world.eid2obj.get(object.eid);
        sourceObj.updateMatrices();

        const objPos = _vec1.setFromMatrixPosition(sourceObj.matrixWorld);
        const cameraPos = _vec2.setFromMatrixPosition(camera.matrixWorld);
        objPos.add(cameraPos.sub(objPos).normalize().multiplyScalar(0.2));
        const clonedMatrixWorld = _mat4.copy(sourceObj.matrixWorld).setPosition(objPos);
        setMatrixWorld(clonedObj, clonedMatrixWorld);

        if (prefabName === "text") {
            window.APP.objectHelper.save(clonedEid, null, "text", initialData, "text");
        } else if (prefabName === "youtube") {
            window.APP.objectHelper.save(clonedEid, null, initialData.link, initialData.link, "youtube");
        } else if (prefabName === "link") {
            window.APP.objectHelper.save(clonedEid, null, initialData.url, initialData.url, "link");
        } else {
            if (initialData.fileasset) {
                // Cloning with same fileasset
                window.APP.objectHelper.save(clonedEid, initialData.fileasset, initialData.title, null, null);
            } else {
                window.APP.objectHelper.save(clonedEid, null, initialData.src, initialData.src);
            }
        }
    };

    const deleteObject = () => {
        if (window.APP.isOnboardingEditor) {
            scene.emit("tip-finished", "delete-image");
        }
        if (hasComponent(world, LiveFeed, object.eid)) {
            // Skip trying to delete Live Video
            removeEntity(world, object.eid);
            scene.emit("video_share_ended");
        } else if (hasComponent(world, IsLocalOnly, object.eid)) {
            removeEntity(world, object.eid);
        } else {
            if (object.name === "NPC") {
                window.APP.objectHelper.delete(object.eid);
            } else {
                window.APP.objectHelper.delete(object.eid);
                removeEntity(world, object.eid);
            }
        }
        closePopover();
    };

    const isLive = hasComponent(world, LiveFeed, object.eid);

    return (
        <div>
            <div className="d-grid gap-2 d-md-block mt-3">
                <p className="fw-bold">Other</p>
            </div>
            <Row>
                <Col>
                    <ButtonGroup vertical className="w-100">
                        <Button variant="purple-dark" className="btn-sm" onClick={() => cloneObject()}>
                            <i className="bi bi-file-earmark-plus"></i>
                            <span className="ms-2">Clone</span>
                        </Button>
                        {isInspecting ? (
                            <Button
                                variant="purple-dark"
                                className="btn-sm ms-2"
                                onClick={() => {
                                    scene.systems["hubs-systems"].cameraSystem.uninspect();
                                    setIsInspecting(false);
                                }}
                            >
                                <i className="bi bi-arrow-return-left" />
                                <span className="ms-2">Stop Inspecting</span>
                            </Button>
                        ) : (
                            <Button
                                variant="purple-dark"
                                className="btn-sm"
                                onClick={() => {
                                    scene.systems["hubs-systems"].cameraSystem.inspect(object, 2);
                                    setIsInspecting(true);
                                }}
                            >
                                <i className="bi bi-eyeglasses" />
                                <span className="ms-2">Inspect</span>
                            </Button>
                        )}
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ButtonGroup vertical className="w-100 pt-2">
                        <Button variant="danger" className="btn-sm" onClick={() => deleteObject()}>
                            <i className="bi bi-trash" />
                            <span className="ms-2">{isLive ? "Stop Sharing" : "Delete"}</span>
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </div>
    );
}
