import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Popover from "react-bootstrap/Popover";
import { createNetworkedEntity } from "../../../../utils/create-networked-entity";
import Card from "react-bootstrap/Card";
import { Tooltip } from "react-tooltip";

export default function CreateGeoGebraMenu(props) {
    const [link, setLink] = useState("");

    const handleSubmit = () => {
        var src = link.split("src=")[1].split(/[ >]/)[0];
        src = src.replace(/^"(.*)"$/, "$1");
        console.log("Geogebra src to store", src);

        const eid = createNetworkedEntity(APP.world, "geogebra", {
            recenter: true,
            resize: true,
            link: src
        });

        const avatarPov = document.querySelector("#avatar-pov-node").object3D;
        const obj = APP.world.eid2obj.get(eid);
        obj.scale.set(1.7, 1.7, 1.7);
        obj.position.copy(avatarPov.localToWorld(new THREE.Vector3(0, 0, -1.5)));
        obj.lookAt(avatarPov.getWorldPosition(new THREE.Vector3()));

        setTimeout(() => {
            window.APP.objectHelper.save(eid, null, "geogebra", src, "geogebra");
        }, 1000);

        props.onFinish();
    };

    return (
        <div className="p-3">
            <Tooltip id="addMediaTooltip" />
            <Button
                data-tooltip-id="addMediaTooltip"
                data-tooltip-content="Back to list"
                draggable={false}
                variant="light"
                onClick={() => props.onBack()}
            >
                <i className="bi bi-arrow-left"></i>
            </Button>
            <Button
                variant="light"
                href="https://megaminds.tawk.help/article/how-do-i-add-geogebra-to-my-lesson"
                target="_blank"
                draggable={false}
                data-tooltip-id="addMediaTooltip"
                data-tooltip-content="How to add GeoGebra to your Lesson"
            >
                <i className="bi bi-question-circle-fill"></i>
            </Button>
            <hr />
            <p>You can place GeoGebra activities in your Lesson.</p>
            <p>Enter the Embed Code to your GeoGebra below.</p>
            <Form.Group controlId="formFile" className="mb-3">
                <Form.Control
                    as="textarea"
                    type="text"
                    name="url"
                    placeholder=""
                    onChange={e => setLink(e.target.value)}
                />
            </Form.Group>
            <Button onClick={() => handleSubmit()} variant="success">
                Add
            </Button>
        </div>
    );
}
