import React, { useState, useEffect } from "react";

import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Card from "react-bootstrap/Card";

import ExtendedModal from "../modals/ExtendedModal";

import BrowseGifMenu from "./galleries/BrowseGifMenu";
import BrowseSmithsonianMenu from "./galleries/BrowseSmithsonianMenu";
import BrowseSketchfabMenu from "./galleries/BrowseSketchfabMenu";
import AddMediaRoot from "./AddMediaRoot";

export default function BrowseMenuContainer(props) {
    const [selectedTab, setSelectedTab] = useState(props.selectedTab);

    useEffect(() => {
        window.APP.scene.addEventListener("left_menu_changed", el => {
            if (el.detail === "gif_menu") {
                setSelectedTab("gif_menu");
            } else if (el.detail === "model_menu") {
                setSelectedTab("model_menu");
            } else if (el.detail === "upload_menu") {
                setSelectedTab("upload_menu");
            }
        });
    }, []);

    return (
        <ExtendedModal
            position="middle-start"
            title={"Content"}
            fixedHeight
            fixedWidth
            closePopover={() => props.closeMenu()}
            className="clickable step-browsemenu"
            content={
                <Tab.Container
                    id="browser-tabs"
                    className="mt-4"
                    defaultActiveKey={selectedTab}
                    activeKey={selectedTab}
                >
                    <Nav variant="tabs">
                        <Nav.Item>
                            <Nav.Link draggable={false} eventKey="upload_menu" onClick={() => setSelectedTab("upload_menu")}>
                                Add
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link draggable={false} eventKey="gif_menu" onClick={() => setSelectedTab("gif_menu")}>
                                Gifs
                            </Nav.Link>
                        </Nav.Item>
                        {/*
                        <Nav.Item>
                            <Nav.Link
                                eventKey="smithsonian_menu"
                                onClick={() => setSelectedTab("smithsonian_menu")}
                            >
                                Smithsonian 3D Models
                            </Nav.Link>
                        </Nav.Item>
                        */}
                        <Nav.Item>
                            <Nav.Link draggable={false} eventKey="model_menu" onClick={() => setSelectedTab("model_menu")}>
                                Models
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content style={{ overflowY: "auto", overflowX: "hidden" }}>
                        <Tab.Pane eventKey="upload_menu">
                            <AddMediaRoot onClose={props.closeMenu} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="gif_menu">
                            <BrowseGifMenu onClose={props.closeMenu} />
                        </Tab.Pane>
                        {/*
                        <Tab.Pane eventKey="smithsonian_menu">
                            <BrowseSmithsonianMenu onClose={props.closeMenu} />
                        </Tab.Pane>
                        */}
                        <Tab.Pane eventKey="model_menu">
                            <BrowseSketchfabMenu onClose={props.closeMenu} />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            }
        />
    );
}
