import { takeOwnership } from "./take-ownership";
import {
    Networked,
    LiveFeed,
    Equirectangular,
    Text,
    IsLocalOnly,
    YouTube,
    Description,
    Billboard,
    StudentsCanMove,
    Link,
    Locked
} from "../bit-components";
import { hasComponent } from "bitecs";

export default class ObjectHelper {
    constructor(websocket, room) {
        this.socket = websocket;
        this.hasSetPermissionsFromServer = false;
        this.permissions = {
            can_change: room.guests_can_move,
            can_chat: room.guests_can_chat,
            can_create: room.guests_can_create,
            can_react: room.guests_can_react,
            can_share_microphone: room.guests_can_share_microphone,
            can_share_video: room.guests_can_share_video,
            can_raise_hand: room.guests_can_raise_hand,
            can_see_student_menu: room.guests_can_see_student_menu,
            can_spawn_stickynotes: room.guests_can_spawn_stickynotes,
            can_draw: room.guests_can_draw,
            is_owner: false,
            is_staff: false,
            is_team: false,
            kick_users: false,
            mute_users: false
        };
    }

    is_teacher() {
        if (this.permissions?.is_owner || this.permissions?.is_team || this.permissions?.is_staff) return true;
        return false;
    }

    can(permission) {
        if (this.permissions?.is_owner || this.permissions?.is_team || this.permissions?.is_staff) return true;

        return this.permissions[permission];
    }

    set_permissions(permissions, initial = false) {
        this.permissions = permissions;

        if (this.hasSetPermissionsFromServer) {
            console.log("Got new permissiosn from teacher");
            if (!permissions.can_share_microphone) {
                APP.dialog.enableMicrophone(false);
            }

            if (permissions.can_share_microphone) {
                window.location.reload();
                APP.dialog.enableMicrophone(true);
            }

            if (!permissions.can_share_video) {
                APP.dialog.enableWebcam(false);
                APP.scene.emit("camera_share_ended");
            }
        }

        this.hasSetPermissionsFromServer = true;
        console.log("got permissions from server", permissions);

        window.APP.scene.emit("finished_permissions_sync");
    }

    get_permissions() {
        try {
            this.socket.send(JSON.stringify({ message: "get_permissions" }));
        } catch (e) {
            console.warn("ObjectHelper: Getting permissions failed", e);
        }
    }

    update_permissions(payload) {
        try {
            this.socket.send(
                JSON.stringify({
                    message: "update_permissions",
                    new_permissions: payload
                })
            );
        } catch (e) {
            console.warn("ObjectHelper: Getting permissions failed", e);
        }
    }

    async save(eid, id, filename = "Unnamed", src = null, type = null, question_id = null, freetext_ref) {
        takeOwnership(APP.world, eid);
        Networked.creator[eid] = APP.getSid("reticulum");
        let mediaType;
        let link_url;

        const obj = window.APP.world.eid2obj.get(eid);
        const nid = APP.getString(Networked.id[eid]);

        if (obj.name === "Interactable Text") {
            mediaType = "text";
            link_url = "to be implemented";
        }

        if (obj.name === "Link") {
            mediaType = "text";
        }

        if (src && !type) {
            mediaType = "external_content";
            link_url = src;
        }

        if (type) {
            if (type === "text") {
                mediaType = "text";
                link_url = null;
            } else if (type === "question") {
                mediaType = "question";
                link_url = null;
            } else if (type === "youtube") {
                mediaType = "youtube";
                link_url = src;
            } else if (type === "npc") {
                mediaType = "npc";
                link_url = null;
            } else {
                mediaType = type;
                link_url = src;
            }
            if (type === "stickynote") {
                mediaType = type;
            }
        }

        const payload = {
            name: filename,
            megaminds: true,
            message: mediaType == "npc" ? src : null,
            text_component_payload: type === "text" ? src : null,
            component_face_user: Billboard.toggled[eid],
            component_disable_grab: Locked.toggled[eid],
            component_allow_students_move: StudentsCanMove.toggled[eid],
            component_360_image: Equirectangular.toggled[eid],
            media_type: mediaType,
            link_url: link_url,
            scale: "[" + obj.scale.x + "," + obj.scale.y + "," + obj.scale.z + "]",
            position: "[" + obj.position.x + "," + obj.position.y + "," + obj.position.z + "]",
            rotation:
                "[" + obj.quaternion.x + "," + obj.quaternion.y + "," + obj.quaternion.z + "," + obj.quaternion.w + "]",
            object_id: nid,
            fileasset: id,
            mcqbundle: question_id,
            freetextbundle: freetext_ref
        };

        console.log("Saving", payload);

        if (!window.APP.editMode) {
            window.APP.scene.emit("setEditMode", true);
        }

        this.socket.send(JSON.stringify({ message: "create", newObject: payload }));
    }

    async delete(eid) {
        try {
            const nid = APP.getString(Networked.id[eid]);
            if (!nid) {
                console.log("Nid was undefined, try parent instead", APP.world.eid2obj.get(eid).parent.eid);
                const parentNid = APP.getString(Networked.id[APP.world.eid2obj.get(eid).parent.eid]);
                this.socket.send(JSON.stringify({ message: "delete", object_id: parentNid }));
            } else {
                this.socket.send(JSON.stringify({ message: "delete", object_id: nid }));
            }
        } catch (e) {
            console.warn("ObjectHelper: Saving Object failed", e);
        }
    }

    set_legacy_finished() {
        this.socket.send(JSON.stringify({ message: "legacy_finished", hub: APP.hub.hub_id }));
    }

    kick_agent(agent_name) {
        console.log("Kicking LiveKit Agent ", agent_name);
        this.socket.send(JSON.stringify({ message: "delete_agent", agent_name: agent_name }));
    }

    dispatchAgent(eid, prompt, voice) {
        const obj = APP.world.eid2obj.get(eid);
        console.log("Dispatching LiveKit Agent for ", eid, obj, voice, prompt);
        const nid = APP.getString(Networked.id[obj.eid]);
        this.socket.send(
            JSON.stringify({ message: "dispatch_agent",  object_id: nid, prompt: prompt, voice: voice })
        );
    }

    async change(eid, newSrc) {
        let obj = APP.world.eid2obj.get(eid);

        if (hasComponent(APP.world, LiveFeed, eid)) {
            console.log("Cannot persist live videos");
            return;
            // Pass on live video, dont save
        }

        if (hasComponent(APP.world, IsLocalOnly, eid)) {
            console.log("Cannot persist local objects");
            return;
            // Pass on local objects such as tutorial items, dont save
        }

        if (obj.parent && obj.parent.name === "Interactable Media") {
            obj = obj.parent;
        }

        takeOwnership(APP.world, obj.eid);

        Networked.creator[eid] = APP.getSid("reticulum");
        const nid = APP.getString(Networked.id[obj.eid]);

        const payload = {
            megaminds: true,
            description: APP.getString(Description.value[eid]),
            component_face_user: Billboard.toggled[eid],
            component_disable_grab: Locked.toggled[eid],
            component_allow_students_move: StudentsCanMove.toggled[eid],
            component_360_image: Equirectangular.toggled[eid],
            scale: "[" + obj.scale.x + "," + obj.scale.y + "," + obj.scale.z + "]",
            position: "[" + obj.position.x + "," + obj.position.y + "," + obj.position.z + "]",
            rotation:
                "[" + obj.quaternion.x + "," + obj.quaternion.y + "," + obj.quaternion.z + "," + obj.quaternion.w + "]",
            object_id: nid,
            hub_id: APP.hub.hub_id
        };

        if (hasComponent(APP.world, Text, eid) && obj.name === "Interactable Text") {
            console.log(APP.getString(Text.value[eid]));
            if (APP.getString(Text.value[eid])) {
                payload["text_component_payload"] = {
                    value: APP.getString(Text.value[eid]),
                    fontSize: 0.2,
                    maxWidth: APP.getString(Text.maxWidth[eid]),
                    textAlign: APP.getString(Text.textAlign[eid]),
                    color: APP.getString(Text.color[eid])
                };
            }
        }

        console.log("Changing... with payload", payload);

        if (hasComponent(APP.world, YouTube, eid) && newSrc) {
            payload["link_url"] = newSrc;
        }

        if (hasComponent(APP.world, Link, eid)) {
            payload["link_url"] = APP.getString(Link.url[eid]);
        }

        this.socket.send(JSON.stringify({ message: "update", newObject: payload }));
    }

    async replace(oldEid, newFileAssetId, filename) {
        console.log("Replacing ", oldEid, "with new source");

        let obj = APP.world.eid2obj.get(oldEid);

        if (obj.parent && obj.parent.name === "Interactable Media") {
            obj = obj.parent;
        }

        takeOwnership(APP.world, oldEid);
        Networked.creator[oldEid] = APP.getSid("reticulum");
        const nid = APP.getString(Networked.id[oldEid]);

        const payload = {
            megaminds: true,
            description: APP.getString(Description.value[oldEid]),
            component_face_user: Billboard.toggled[oldEid],
            component_disable_grab: Locked.toggled[oldEid],
            component_allow_students_move: StudentsCanMove.toggled[oldEid],
            fileasset: newFileAssetId,
            scale: "[" + obj.scale.x + "," + obj.scale.y + "," + obj.scale.z + "]",
            position: "[" + obj.position.x + "," + obj.position.y + "," + obj.position.z + "]",
            rotation:
                "[" + obj.quaternion.x + "," + obj.quaternion.y + "," + obj.quaternion.z + "," + obj.quaternion.w + "]",
            object_id: nid,
            hub_id: APP.hub.hub_id
        };

        this.socket.send(JSON.stringify({ message: "update", newObject: payload }));
    }
}
