import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Popover from "react-bootstrap/Popover";
import Form from "react-bootstrap/Form";
import { renderStickyNote } from "../../../misc/sticky-note.js";
import { Tooltip } from "react-tooltip";

export default function CreateStickynoteMenu(props) {
    const [text, setText] = useState("");
    const [creating, setCreating] = useState(false);

    const handleSubmit = () => {
        setCreating(true);
        renderStickyNote(text).then(() => {
            setCreating(false);
            props.onFinish();
        });
    };

    return (
        <div className="p-3">
            <Tooltip id="addMediaTooltip" />
            <Button
                data-tooltip-id="addMediaTooltip"
                data-tooltip-content="Back to list"
                variant="light"
                onClick={() => props.onBack()}
                draggable={false}
            >
                <i className="bi bi-arrow-left"></i>
            </Button>
            <Button
                data-tooltip-id="addMediaTooltip"
                data-tooltip-content="Guide to Components"
                href="https://megaminds.tawk.help/article/components"
                target="_blank"
                variant="light"
                draggable={false}
            >
                <i className="bi bi-question-circle-fill"></i>
            </Button>
            <hr />
            <p className="fw-bold">Create Stickynote</p>
            <Form.Group controlId="formFile" className="mb-3">
                <Form.Control
                    type="text"
                    name="url"
                    placeholder="Text to appear in stickynote"
                    onChange={e => setText(e.target.value)}
                />
            </Form.Group>
            {creating ? (
                <Button disabled variant="success">
                    Creating...
                </Button>
            ) : (
                <Button onClick={() => handleSubmit()} variant="success">
                    Add
                </Button>
            )}
        </div>
    );
}
