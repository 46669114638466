import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Popover from "react-bootstrap/Popover";

import youtube_model from "../../../../assets/models/youtube.glb";
import { createNetworkedEntity } from "../../../../utils/create-networked-entity";

import { Tooltip } from "react-tooltip";

function youtube_parser(url) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    if (!match) return "error";

    return match && match[7].length == 11 ? match[7] : false;
}

let validLink;

export default function YouTubeMenu(props) {
    const [link, setLink] = useState("");

    const handleSubmit = () => {
        if (link.startsWith("https://www.youtube.com/embed")) {
            console.log("GOT EMBED LINK", link);
            validLink = link;
        } else {
            validLink = youtube_parser(link);
            if (validLink === "error") {
                console.error("Invalid youtube link");
                return;
            } else {
                validLink = "https://youtube.com/embed/" + validLink;
            }
        }

        const eid = createNetworkedEntity(APP.world, "youtube", {
            src: youtube_model,
            recenter: true,
            resize: true,
            link: validLink
        });

        const avatarPov = document.querySelector("#avatar-pov-node").object3D;
        const obj = APP.world.eid2obj.get(eid);
        obj.position.copy(avatarPov.localToWorld(new THREE.Vector3(0, 0, -1.5)));
        obj.scale.set(1.7, 1.7, 1.7);
        obj.lookAt(avatarPov.getWorldPosition(new THREE.Vector3()));

        setTimeout(() => {
            window.APP.objectHelper.save(eid, null, "YouTube Video", link, "youtube");
        }, 1000);

        props.onFinish();
    };

    return (
        <div className="p-3">
            <Tooltip id="addMediaTooltip" />
            <Button
                data-tooltip-id="addMediaTooltip"
                data-tooltip-content="Back to list"
                variant="light"
                onClick={() => props.onBack()}
                draggable={false}
            >
                <i className="bi bi-arrow-left"></i>
            </Button>
            <Button
                data-tooltip-id="addMediaTooltip"
                data-tooltip-content="Guide to Components"
                href="https://megaminds.tawk.help/article/components"
                target="_blank"
                variant="light"
                draggable={false}
            >
                <i className="bi bi-question-circle-fill"></i>
            </Button>
            <hr />
            <p className="fw-bold">Add YouTube Video</p>
            <Form.Group controlId="formFile" className="mb-3">
                <Form.Control
                    type="text"
                    name="url"
                    placeholder="https://www.youtube.com/watch?v=eYIjuMCe0CI"
                    onChange={e => setLink(e.target.value)}
                />
            </Form.Group>
            <Button onClick={() => handleSubmit()} variant="success">
                Add
            </Button>
        </div>
    );
}
