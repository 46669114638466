import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import ListGroup from "react-bootstrap/ListGroup";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import { spawnFromFileList, spawnFromUrl } from "../../../load-media-on-paste-or-drop";
import { Tooltip } from "react-tooltip";

import KahootIcon from "../../../assets/images/kahoot.png?inline";
import GeoGebraIcon from "../../../assets/images/geogebra.png?inline";
import NearpodIcon from "../../../assets/images/nearpod.png?inline";
import QuizizzIcon from "../../../assets/images/quizizz.png?inline";
import PeardeckIcon from "../../../assets/images/peardeck.png?inline";
import PolypadIcon from "../../../assets/images/polypad.png?inline";

import CreateTextMenu from "./addmenus/Text";
import CreateLinkMenu from "./addmenus/Link";
import CreateYouTubeMenu from "./addmenus/YouTube";
import CreateStickynoteMenu from "./addmenus/Stickynote";
import CreateFreeTextQuestionMenu from "./addmenus/FreeTextQuestion";
import CreateKahootMenu from "./addmenus/Kahoot";
import CreateNPCMenu from "./addmenus/NPC";
import CreateGeoGebraMenu from "./addmenus/GeoGebra";
import CreatePeardeckMenu from "./addmenus/Peardeck";
import CreateQuizizzMenu from "./addmenus/Quizizz";
import CreateNearpodMenu from "./addmenus/Nearpod";
import CreatePolypadMenu from "./addmenus/Polypad";
import CreateMCQMenu from "./addmenus/MultipleChoice";

function AddMediaRoot(props) {
    // For basic upload
    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    const handleChange = event => {
        spawnFromFileList(event.target.files);
    };

    const [menu, setMenu] = useState(null);

    const onFinish = () => {
        props.onClose();
    };

    const onBack = () => {
        setMenu(null);
    };

    const isPremium = window.APP.classroom.subscription;

    if (menu === "text") {
        return <CreateTextMenu onBack={onBack} onFinish={onFinish} />;
    }

    if (menu === "link") {
        return <CreateLinkMenu onBack={onBack} onFinish={onFinish} />;
    }

    if (menu === "youtube") {
        return <CreateYouTubeMenu onBack={onBack} onFinish={onFinish} />;
    }

    if (menu === "geogebra") {
        return <CreateGeoGebraMenu onBack={onBack} onFinish={onFinish} />;
    }

    if (menu === "stickynote") {
        return <CreateStickynoteMenu onBack={onBack} onFinish={onFinish} />;
    }

    if (menu === "freetextquestion") {
        return <CreateFreeTextQuestionMenu onBack={onBack} onFinish={onFinish} />;
    }

    if (menu === "kahoot") {
        return <CreateKahootMenu onBack={onBack} onFinish={onFinish} />;
    }

    if (menu === "peardeck") {
        return <CreatePeardeckMenu onBack={onBack} onFinish={onFinish} />;
    }

    if (menu === "quizizz") {
        return <CreateQuizizzMenu onBack={onBack} onFinish={onFinish} />;
    }

    if (menu === "nearpod") {
        return <CreateNearpodMenu onBack={onBack} onFinish={onFinish} />;
    }

    if (menu === "polypad") {
        return <CreatePolypadMenu onBack={onBack} onFinish={onFinish} />;
    }

    if (menu === "multiplechoice") {
        return <CreateMCQMenu onBack={onBack} onFinish={onFinish} />;
    }

    if (menu === "npc") {
        return <CreateNPCMenu onBack={onBack} onFinish={onFinish} />;
    }

    const list = [
        {
            title: "General",
            items: [
                {
                    title: "NPC",
                    description: "Create an NPC",
                    icon: <i className="bi bi-upload"></i>,
                    onClick: () => setMenu("npc"),
                    premium: false,
                    comingSoon: false
                },
                {
                    title: "Media",
                    description: "Upload a video, image or other files.",
                    icon: <i className="bi bi-upload"></i>,
                    onClick: handleClick,
                    premium: false,
                    comingSoon: false
                },
                {
                    title: "Text",
                    description: "Add text",
                    icon: <i className="bi bi-fonts"></i>,
                    onClick: () => setMenu("text"),
                    premium: false,
                    comingSoon: false
                },
                {
                    title: "Link",
                    description: "Link to a website",
                    icon: <i className="bi bi-link-45deg"></i>,
                    onClick: () => setMenu("link"),
                    premium: false,
                    comingSoon: false
                },
                {
                    title: "YouTube",
                    description: "Add a YouTube video",
                    icon: <i className="bi bi-youtube"></i>,
                    onClick: () => setMenu("youtube"),
                    premium: false,
                    comingSoon: false
                },
                {
                    title: "Stickynote",
                    description: "Add a small note",
                    icon: <i className="bi bi-sticky"></i>,
                    onClick: () => setMenu("stickynote"),
                    premium: false,
                    comingSoon: false
                }
            ]
        },
        {
            title: "Assessment",
            items: [
                {
                    title: "Multiple Choice Question",
                    description: "Add a multiple choice question form.",
                    icon: <i className="bi bi-ui-checks-grid"></i>,
                    onClick: () => setMenu("multiplechoice"),
                    premium: false,
                    comingSoon: false
                },
                {
                    title: "Freetext Question",
                    description: "Add a set of freetext questions.",
                    icon: <i className="bi bi-card-text"></i>,
                    onClick: () => setMenu("freetextquestion"),
                    premium: false,
                    comingSoon: false
                },
                {
                    title: "Flipcard",
                    description: "Add a flipcard that users can practice with",
                    icon: <i className="bi bi-phone-flip"></i>,
                    onClick: () => null,
                    premium: false,
                    comingSoon: true
                }
            ]
        },
        {
            title: "EdTech",
            items: [
                {
                    title: "Kahoot",
                    description: "Add Kahoot",
                    icon: <img className="img-fluid" src={KahootIcon} />,
                    onClick: () => setMenu("kahoot"),
                    premium: true,
                    comingSoon: false
                },
                {
                    title: "Peardeck",
                    description: "Add Peardeck",
                    icon: <img className="img-fluid" src={PeardeckIcon} />,
                    onClick: () => setMenu("peardeck"),
                    premium: true,
                    comingSoon: false
                },
                {
                    title: "Quizizz",
                    description: "Add Quizizz",
                    icon: <img className="img-fluid" src={QuizizzIcon} />,
                    onClick: () => setMenu("quizizz"),
                    premium: true,
                    comingSoon: false
                },
                {
                    title: "Nearpod",
                    description: "Add Nearpod",
                    icon: <img className="img-fluid" src={NearpodIcon} />,
                    onClick: () => setMenu("nearpod"),
                    premium: true,
                    comingSoon: false
                },
                {
                    title: "GeoGebra",
                    description: "Add GeoGebra",
                    icon: <img className="img-fluid" src={GeoGebraIcon} />,
                    onClick: () => setMenu("geogebra"),
                    premium: false,
                    comingSoon: false
                },
                {
                    title: "Polypad",
                    description: "Add Mathigon Polypad",
                    icon: <img className="img-fluid" src={PolypadIcon} />,
                    onClick: () => setMenu("polypad"),
                    premium: false,
                    comingSoon: false
                }
            ]
        }
    ];

    return (
        <Row className="p-3 user-select-none">
            <Col xs={12}>
                <input type="file" ref={hiddenFileInput} multiple onChange={handleChange} style={{ display: "none" }} />
                {list.map(category => (
                    <>
                        <p className="fw-bold mt-2 mb-2">{category.title}</p>
                        <ListGroup>
                            {category.items.map(item => (
                                <ListGroup.Item
                                    as="li"
                                    action
                                    disabled={(!isPremium && item.premium) || item.comingSoon}
                                    onClick={item.onClick}
                                >
                                    <Row>
                                        <Col xs={2} className="d-flex justify-content-center align-items-center">
                                            {item.icon}
                                        </Col>
                                        <Col xs={10}>
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">
                                                    {item.title}
                                                    {!isPremium && item.premium && (
                                                        <span
                                                            className="badge text-dark float-end fw-light"
                                                            style={{ backgroundColor: "gold" }}
                                                        >
                                                            <small>Premium</small>
                                                        </span>
                                                    )}
                                                    {item.comingSoon && (
                                                        <span className="badge text-dark float-end bg-success fw-light text-white">
                                                            <small>Coming Soon</small>
                                                        </span>
                                                    )}
                                                </div>
                                                <small className="fw-light">{item.description}</small>
                                            </div>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </>
                ))}
            </Col>
        </Row>
    );
}

export default AddMediaRoot;
