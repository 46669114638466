import { addComponent } from "bitecs";
import { updateAudioSettings } from "../utils/update-audio-settings";
import { addObject3DComponent } from "../utils/jsx-entity";
import { MediaAudio, AudioEmitter, AIAgent, MorphAudioFeedback } from "../bit-components";
import { SourceType } from "../components/audio-params";

export function inflateAgentAudio(world, eid, props) {
    console.log("INFLATING AGENT AUDIO", props, eid);

    // Add audio components
    addComponent(world, AIAgent, eid);
    addComponent(world, AudioEmitter, eid);
    addComponent(world, MediaAudio, eid);

    // Create empty object3D as parent
    const obj = new THREE.Object3D();
    let newEid = addObject3DComponent(world, eid, obj);

    // Create and configure positional audio
    const positionalAudio = new THREE.PositionalAudio(APP.audioListener);
    positionalAudio.setRefDistance(1.5);
    positionalAudio.setRolloffFactor(4);
    positionalAudio.setDistanceModel("inverse");
    positionalAudio.setVolume(1.0);

    // Store reference and add to scene
    MediaAudio.ref[eid] = APP.getSid(positionalAudio);
    const worldObj = APP.world.eid2obj.get(newEid);
    worldObj.add(positionalAudio);

    // Add to audio system for global audio management
    console.log("INFLATED AGENT AUDIO, BUT CONTEXT IS ", THREE.AudioContext.getContext().state);
    const audioSystem = APP.scene.systems["hubs-systems"].audioSystem;
    audioSystem.addAudio({
        sourceType: SourceType.AVATAR_AUDIO_SOURCE,
        node: positionalAudio
    });

    worldObj.prompt = props.prompt;
    worldObj.voice = props.voice;
    worldObj.object_id = props.object_id;

    // Store the update function on the object itself

    worldObj.updateAgentAudio = agent => {
        console.log("Updating agent audio for:", agent);

        const attachAudioTrack = audioTrack => {
            try {
                if (!audioTrack.isSubscribed) {
                    console.log("Audio track not subscribed yet, retrying...");
                    setTimeout(() => worldObj.updateAgentAudio(agent), 100); // Retry
                    return;
                }

                const track = audioTrack.track;
                if (!track || !track.mediaStream) {
                    console.warn("No valid track or media stream found, retrying...");
                    setTimeout(() => worldObj.updateAgentAudio(agent), 100); // Retry
                    return;
                }

                const audioContext = THREE.AudioContext.getContext();

                const destination = audioContext.createMediaStreamDestination();
                const source = audioContext.createMediaStreamSource(track.mediaStream);
                const destinationSource = audioContext.createMediaStreamSource(destination.stream);
                source.connect(destination);

                positionalAudio.setNodeSource(destinationSource);

                console.log("Positional audio, check for gain", positionalAudio);

                console.log("Attached audio track to positional audio.", positionalAudio);

                console.log("Agent audio successfully attached.");
            } catch (error) {
                console.error("Error attaching audio track:", error);
            }
        };

        const audioTrack = Array.from(agent.trackPublications.values()).find(pub => pub.kind === "audio");



        if (!audioTrack) {
            console.warn("No audio track found for agent, retrying...");
            setTimeout(() => worldObj.updateAgentAudio(agent), 100); // Retry
            return;
        }

        attachAudioTrack(audioTrack);
    };

    return eid;
}
