import React, { useState, useEffect, useRef } from "react";
import { removeEntity } from "bitecs";
import { Text } from "../../../../bit-components";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { SketchPicker } from "react-color";
import Popover from "react-bootstrap/Popover";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { createNetworkedEntity } from "../../../../utils/create-networked-entity";
import { Tooltip } from "react-tooltip";

const colors = [
    "#000", // Black
    "#424242", // Gray
    "#F44336", // Red
    "#9C27B0", // Purple red
    "#7E57C2", // Purple blue
    "#5C6BC0", // Dark blue
    "#42A5F5", // Light Blue
    "#90CAF9", // Lightest blue
    "#B2DFDB", // Teal
    "#81C784", // Light green
    "#8BC34A", // Forest green
    "#FFEB3B", // Yellow
    "#FFA726", // Orange
    "#FFCC80", // Lighter Orange
    "#A1887F", // Light brown
    "#4E342E", // Deep Brown
    "#EEEEEE", // Light gray
    "#FAFAFA" // White
];

export default function CreateTextMenu(props) {
    const [showPicker, setShowPicker] = useState(false);
    const ref = useRef(null);
    const [text, setText] = useState("Preview");
    const [maxWidth, setMaxWidth] = useState(2);
    const [align, setAlign] = useState("left");
    const [color, setColor] = useState("#ffffff");
    const [isSaving, setIsSaving] = useState(false);
    const pickerRef = useRef(null);

    useEffect(() => {
        const textPayload = {
            value: text,
            fontSize: 0.2,
            maxWidth: maxWidth,
            textAlign: align,
            color: color
        };

        const eid = createNetworkedEntity(APP.world, "text", textPayload);
        const avatarPov = document.querySelector("#avatar-pov-node").object3D;
        const obj = APP.world.eid2obj.get(eid);
        obj.position.copy(avatarPov.localToWorld(new THREE.Vector3(0, 0, -1.5)));
        obj.lookAt(avatarPov.getWorldPosition(new THREE.Vector3()));

        ref.current = obj;

        return () => {
            if (ref.current !== null) {
                setText("Preview");
                setColor("#ffffff");
                setShowPicker(false);
                removeEntity(APP.world, ref.current.eid);
            }
        };
    }, []);

    useEffect(() => {
        if (ref.current) {
            console.log("Text changed");
            Text.value[ref.current.eid] = APP.getSid(text);
            Text.maxWidth[ref.current.eid] = APP.getSid(maxWidth);
            Text.color[ref.current.eid] = APP.getSid(color);
            Text.textAlign[ref.current.eid] = APP.getSid(align);
            ref.current.sync();
        }
    }, [text, color, maxWidth, align]);

    const addText = () => {
        const textPayload = {
            value: text,
            fontSize: 0.2,
            maxWidth: maxWidth,
            textAlign: align,
            color: color
        };

        const eid = createNetworkedEntity(APP.world, "text", textPayload);
        const avatarPov = document.querySelector("#avatar-pov-node").object3D;
        const obj = APP.world.eid2obj.get(eid);
        obj.position.copy(avatarPov.localToWorld(new THREE.Vector3(0, 0, -1.5)));
        obj.lookAt(avatarPov.getWorldPosition(new THREE.Vector3()));

        setTimeout(() => {
            console.log("Saving text", textPayload);
            window.APP.objectHelper.save(obj.eid, null, "text", textPayload, "text");
        }, 1000);

        props.onFinish();
    };

    const handleChangeComplete = color => {
        setColor(color.hex);
    };

    const handleClickOutside = (event) => {
        if (pickerRef.current && !pickerRef.current.contains(event.target)) {
            setShowPicker(false);
        }
    };

    useEffect(() => {
        if (showPicker) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showPicker]);

    return (
        <div className="step-createtext p-3">
            <Tooltip id="addMediaTooltip" />
            <Button
                data-tooltip-id="addMediaTooltip"
                data-tooltip-content="Back to list"
                variant="light"
                onClick={() => props.onBack()}
                draggable={false}
            >
                <i className="bi bi-arrow-left"></i>
            </Button>
            <Button
                data-tooltip-id="addMediaTooltip"
                data-tooltip-content="Guide to Components"
                href="https://megaminds.tawk.help/article/components"
                target="_blank"
                variant="light"
                draggable={false}
            >
                <i className="bi bi-question-circle-fill"></i>
            </Button>
            <hr />
            <p className="fw-bold">Create Text</p>
            <Row>
                <Col xs={12}>
                    <textarea
                        id="troika-value-input"
                        className="mt-2 form-control"
                        style={{ height: "110px" }}
                        value={text}
                        type="text"
                        onChange={e => setText(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs={4} className="d-flex justify-content-center align-items-center">
                    <ButtonGroup>
                        <Button variant={align === "left" ? "success" : "purple-dark"} onClick={() => setAlign("left")}>
                            <i className="bi bi-text-left"></i>
                        </Button>
                        <Button
                            variant={align === "center" ? "success" : "purple-dark"}
                            onClick={() => setAlign("center")}
                        >
                            <i className="bi bi-text-center"></i>
                        </Button>
                        <Button
                            variant={align === "right" ? "success" : "purple-dark"}
                            onClick={() => setAlign("right")}
                        >
                            <i className="bi bi-text-right"></i>
                        </Button>
                    </ButtonGroup>
                </Col>
                <Col xs={8}>
                    <Form.Label>Max Width</Form.Label>
                    <Form.Range max="3" min="0.2" step="0.1" onChange={e => setMaxWidth(e.target.value)} />
                </Col>
            </Row>
            <Row className="mt-3">
            </Row>
            <Row className="mt-3">
                {colors.map(color => (
                    <Col>
                        <button
                            type="button"
                            className="p-2 mt-3 form-control"
                            style={{
                                backgroundColor: color,
                                width: "25px",
                                height: "25px",
                                border: "1px solid #ccc"
                            }}
                            onClick={() => handleChangeComplete({ hex: color })}
                        />
                    </Col>
                ))}
                <Col>
                    {showPicker && (
                        <>
                            <div 
                                ref={pickerRef}
                                style={{
                                    position: "relative",
                                    top: "300%",
                                    zIndex: 1000,
                                }}
                            >
                                <SketchPicker color={color} onChange={handleChangeComplete} />
                            </div>
                        </>
                    )}
                    <button
                        type="button"
                        className="p-2 mt-3 form-control"
                        style={{
                            background: "linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet, red)",
                            width: "25px",
                            height: "25px",
                            border: "1px solid #ccc"
                        }}
                        onClick={() => setShowPicker(!showPicker)}
                    />
                </Col>
            </Row>
            <hr />
            <Button className="mt-3 me-2" variant="success" type="button" onClick={() => addText()}>
                Add Text
            </Button>
            <Button className="mt-3" variant="secondary" type="button" onClick={() => props.onBack()}>
                Cancel
            </Button>
        </div>
    );
}
