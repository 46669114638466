import { MediaLoaderParams } from "../inflators/media-loader";
import { ParticleEmitterParams } from "../inflators/reaction";
import { MediaPrefab } from "./media";
import { ReactionPrefab } from "./reaction";
import { QuestionPrefab } from "./question";
import { PeardeckPrefab } from "./peardeck";
import { NearpodPrefab } from "./nearpod";
import { QuizizzPrefab } from "./quizizz";
import { GimkitPrefab } from "./gimkit";
import { NPCPrefab } from "./npc";
import { PolypadPrefab } from "./polypad";
import { KahootPrefab } from "./kahoot";
import { GeoGebraPrefab } from "./geogebra";
import { TextPrefab } from "./text";
import { YouTubePrefab } from "./youtube";
import { LinkPrefab } from "./link";
import { EntityDef } from "../utils/jsx-entity";

type MediaPrefabT = (params: MediaLoaderParams) => EntityDef;
type ReactionPrefabT = (params: ParticleEmitterParams) => EntityDef;
type QuestionPrefabT = () => EntityDef;
type YouTubePrefab = () => EntityDef;

export type PrefabDefinition = {
    template: MediaPrefabT | ReactionPrefabT | QuestionPrefabT;
};

export type PrefabName =
    | "peardeck"
    | "nearpod"
    | "text"
    | "quizizz"
    | "npc"
    | "geogebra"
    | "gimkit"
    | "polypad"
    | "kahoot"
    | "media"
    | "reaction"
    | "question"
    | "youtube"
    | "link";

export const prefabs = new Map<PrefabName, PrefabDefinition>();

prefabs.set("media", { template: MediaPrefab });
prefabs.set("gimkit", { template: GimkitPrefab });
prefabs.set("polypad", { template: PolypadPrefab });
prefabs.set("reaction", { template: ReactionPrefab });
prefabs.set("question", { template: QuestionPrefab });
prefabs.set("youtube", { template: YouTubePrefab });
prefabs.set("link", { template: LinkPrefab });
prefabs.set("text", { template: TextPrefab });
prefabs.set("npc", { template: NPCPrefab });
prefabs.set("kahoot", { template: KahootPrefab });
prefabs.set("geogebra", { template: GeoGebraPrefab });
prefabs.set("peardeck", { template: PeardeckPrefab });
prefabs.set("nearpod", { template: NearpodPrefab });
prefabs.set("quizizz", { template: QuizizzPrefab });
