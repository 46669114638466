import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { Tooltip } from "react-tooltip";

import { createNetworkedEntity } from "../../../../utils/create-networked-entity";

import Card from "react-bootstrap/Card";

export default function CreateQuizizzMenu(props) {
    const [link, setLink] = useState("");

    const handleSubmit = () => {
        const eid = createNetworkedEntity(APP.world, "quizizz", {
            recenter: true,
            resize: true,
            link: link
        });

        const avatarPov = document.querySelector("#avatar-pov-node").object3D;
        const obj = APP.world.eid2obj.get(eid);
        obj.position.copy(avatarPov.localToWorld(new THREE.Vector3(0, 0, -1.5)));
        obj.scale.set(1.7, 1.7, 1.7);
        obj.lookAt(avatarPov.getWorldPosition(new THREE.Vector3()));

        setTimeout(() => {
            window.APP.objectHelper.save(eid, null, link, link, "quizizz");
        }, 1000);

        props.onFinish();
    };

    return (
        <div className="p-3">
            <Tooltip id="addMediaTooltip" />
            <Button
                data-tooltip-id="addMediaTooltip"
                data-tooltip-content="Back to list"
                variant="light"
                onClick={() => props.onBack()}
                draggable={false}
            >
                <i className="bi bi-arrow-left"></i>
            </Button>
            <Button
                href="https://megaminds.tawk.help/article/can-i-add-quizizz-to-my-lesson"
                target="_blank"
                variant="light"
                data-tooltip-id="addMediaTooltip"
                data-tooltip-content="How to add Nearpod to your Lesson"
                draggable={false}
            >
                <i className="bi bi-question-circle-fill"></i>
            </Button>
            <hr />
            <p>Use a Quizizz Game Code to add Quizizz.</p>
            <Form.Group controlId="formFile" className="mb-3">
                <Form.Control
                    type="text"
                    name="url"
                    placeholder="https://quizizz.com/join?gc=16054671"
                    onChange={e => setLink(e.target.value)}
                />
            </Form.Group>
            <Button onClick={() => handleSubmit()} variant="success">
                Add
            </Button>
        </div>
    );
}
