import { defineQuery, enterQuery } from "bitecs";
import { MorphAudioFeedback } from "../../bit-components"; // MorphAudioFeedback component
import * as THREE from "three";
import { easeOutQuadratic } from "../../utils/easing";

// Query definitions
const morphAudioQuery = defineQuery([MorphAudioFeedback]);

let frameCounter = 0; // Counter to track frame updates

const audioAnalyserCache = {};

export function morphAudioSystem(world) {
    // Process all entities with the MorphAudioFeedback component
    morphAudioQuery(world).forEach(eid => {
        if (!window.APP.entryManager._entered) return;
        if (frameCounter % 5 === 0) {
            const entityObject = world.eid2obj.get(eid); // Get the 3D entity
            if (!entityObject || !entityObject.isObject3D) return;

            const sceneObject = entityObject.children.find(child => child.name === "Scene");
            if (!sceneObject || !sceneObject.isObject3D) return;

            const morphMeshes = [];
            sceneObject.traverse(obj => {
                if (obj.morphTargetDictionary) {
                    morphMeshes.push(obj);
                }
            });

            if (!morphMeshes.length) return; // Skip if no cached morphMeshes found for this eid

            // Check if the audio analyser exists for this entity, if not, create it
            // Only update the audio analyzer every 4th frame (15 FPS)
            let analyser = audioAnalyserCache[eid];
            if (!analyser) {
                const audioSource = entityObject.children.find(child => child instanceof THREE.PositionalAudio);
                if (audioSource) {
                    analyser = new THREE.AudioAnalyser(audioSource, 32); // FFT size
                    audioAnalyserCache[eid] = analyser; // Store in cache
                } else {
                    return; // Skip if no audio source found for this entity
                }
            }

            // Get the audio level (or volume) from the analyser
            const audioLevel = analyser.getAverageFrequency(); // Returns average frequency value

            // Map the audio level to morph target influences
            morphMeshes.forEach(mesh => {
                const mouthCloseIndex = mesh.morphTargetDictionary["mouthClose"];
                const mouthOpenIndex = mesh.morphTargetDictionary["mouthOpen"];

                if (mouthCloseIndex !== undefined && mouthOpenIndex !== undefined && mesh.morphTargetInfluences) {
                    const smoothedAudioLevel = easeOutQuadratic(audioLevel) / 10000; // Apply smoothing (optional)

                    // Use smoothedAudioLevel for both morphs
                    const mouthCloseValue = Math.max(0, smoothedAudioLevel + 0.1); // Add a small offset to prevent fully closed mouth
                    const mouthOpenValue = Math.min(1, -smoothedAudioLevel); // Positive for opening mouth

                    mesh.morphTargetInfluences[mouthCloseIndex] = mouthCloseValue;
                    mesh.morphTargetInfluences[mouthOpenIndex] = mouthOpenValue;
                }
            });
        }
    });
}
