import EventEmitter from "eventemitter3";
import { Room, RoomEvent } from "livekit-client";
import { createNetworkedEntity } from "./utils/create-networked-entity";

export const DIALOG_CONNECTION_CONNECTED = "dialog-connection-connected";
export const DIALOG_CONNECTION_ERROR_FATAL = "dialog-connection-error-fatal";

export class DialogAdapter extends EventEmitter {
    constructor() {
        super();

        console.info("Constructing RTC Adapter");
        this.room = undefined;
        this.connected = false;
        this._clientId = undefined;
        this.agentMap = new Map(); // Map of object_id -> agent participant
    }

    async initialize({ clientId }) {
        this._clientId = clientId;
        this.room = new Room();
    }

    async connectToRoom(token) {
        const wsURL = process.env.REACT_APP_LIVEKIT_LINK;

        console.log("Attempting to connect to LiveKit", token.token);
        this.room.connect(wsURL, token.token).then(room => {
            console.log("Connected to room", room, this.room.remoteParticipants);

            this.room.on(RoomEvent.ParticipantConnected, participant => {
                console.log("Participant connected:", participant);

                const getWorldObjectForAgent = objectId => {
                    const entityId = APP.world.nid2eid.get(APP.getSid(objectId));
                    return APP.world.eid2obj.get(entityId);
                };

                // Check if this is an agent
                if (participant.attributes && participant.attributes["object_id"]) {
                    const objectId = participant.attributes["object_id"];
                    console.log("Found NPC agent for object:", objectId);

                    // Map the participant to the agent object
                    this.agentMap.set(objectId, participant);

                    const initializeAudioForParticipant = participant => {
                        const obj = getWorldObjectForAgent(objectId);
                        if (obj && obj.updateAgentAudio) {
                            console.log("Initializing or updating agent audio for object:", obj);
                            obj.updateAgentAudio(participant);
                        } else {
                            console.warn("Could not find object for audio initialization:", objectId);
                        }
                    };

                    // Set up track publication handler
                    participant.on("trackPublished", track => {
                        console.log("Track published:", track.kind, track);
                        if (track.kind === "audio") {
                            initializeAudioForParticipant(participant);
                        }
                    });

                    // Set up track subscription handler
                    participant.on("trackSubscribed", track => {
                        console.log("Track subscribed:", track.kind, track);
                        if (track.kind === "audio") {
                            initializeAudioForParticipant(participant);
                        }
                    });

                    // Initialize audio if tracks are already published
                    initializeAudioForParticipant(participant);
                }
            });

            this.room.on(RoomEvent.ParticipantDisconnected, participant => {
                // Remove from agent map if it was an agent
                for (const [objectId, agent] of this.agentMap.entries()) {
                    if (agent === participant) {
                        this.agentMap.delete(objectId);
                        break;
                    }
                }
            });

            this.room.localParticipant
                .setMicrophoneEnabled(window.APP.objectHelper.can("can_share_microphone"))
                .then(() => {
                    if (window.APP.objectHelper.can("can_share_microphone")) {
                        // todo
                    }
                    setTimeout(() => {
                        window.APP.scene.emit("livekit_loaded");
                    });
                })
                .catch(error => {
                    console.log("Got error when trying to set mic enabled, most likely blocked");
                    setTimeout(() => {
                        window.APP.scene.emit("livekit_loaded");
                    }, 500);
                });

            this.connected = true;
            console.log("Connected to LiveKit");
            this.emit(DIALOG_CONNECTION_CONNECTED);
        });
    }

    getAgentForObject(objectId) {
        return this.agentMap.get(objectId);
    }

    getLiveKitUser(clientId) {
        return this.room.remoteParticipants.get(clientId);
    }

    async getMediaStream(clientId, kind = "audio") {
        if (!this.connected) {
            console.log("Attempted to get Media Stream before connecting");
        }
        // This function allows you to request a mediastream from yourself (local)
        // or a remote user that is also connected to livekit.

        let track;

        if (this._clientId === clientId) {
            // This is our own local stream
            console.log("Local user shared their stream", kind);

            if (kind === "audio") {
                track = this.room.localParticipant.getTrackPublication("microphone").track.mediaStream;
            } else if (kind === "video") {
                track = this.room.localParticipant.getTrackPublication("camera").track.mediaStream;
            } else if (kind === "screen") {
                track = this.room.localParticipant.getTrackPublication("screen_share").track.mediaStream;
            }
        } else {
            // Someone else in the room has shared a stream
            let user = this.getLiveKitUser(clientId);

            if (user && kind === "audio") {
                console.log("Remote user shared their microphone", kind, user);
                track = user.getTrackPublication("microphone").track.mediaStream;
            } else if (user && kind === "video") {
                console.log("Remote user shared their camera", kind, user);
                track = user.getTrackPublication("camera").track.mediaStream;
            } else if (user && kind === "screen") {
                console.log("Remote user shared their camera", kind, user);
                track = user.getTrackPublication("screen_share").track.mediaStream;
            }
        }

        if (track) {
            // Found mediastream successfully
            return Promise.resolve(track);
        } else {
            console.error("Could not get mediastream");
            return Promise.resolve(null);
        }
    }

    async enableMicrophone(enabled) {
        console.log("Setting microphone enabled to ", enabled);
        this.room.localParticipant.setMicrophoneEnabled(enabled);
        this.emit("mic-state-changed", { enabled });
    }

    async enableWebcam(enabled) {
        this.room.localParticipant.setCameraEnabled(enabled);
    }

    async enableScreenShare(enabled) {
        this.room.localParticipant.setScreenShareEnabled(enabled);
    }

    kick(clientId) {
        document.body.dispatchEvent(new CustomEvent("kicked", { detail: { clientId: clientId } }));
    }

    block(clientId) {
        document.body.dispatchEvent(new CustomEvent("blocked", { detail: { clientId: clientId } }));
    }

    unblock(clientId) {
        document.body.dispatchEvent(new CustomEvent("unblocked", { detail: { clientId: clientId } }));
    }
}
