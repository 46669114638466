import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ProfileStep } from "../../home/ProfileStep";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";

export default function ChangeAvatarButton() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleSave = (avatar, displayName) => {
        window.APP.store.update({
            profile: { avatarId: avatar.id, displayName: displayName }
        });
        handleClose();
    };
    const handleShow = () => setShow(true);

    return (
        <>
            <Dropdown.Item onClick={handleShow} draggable={false}>
                <FontAwesomeIcon icon={faUserEdit} color="#6f6ec4" />
                <span className="ms-2 mb-0">Change Name and Avatar</span>
            </Dropdown.Item>
            <Modal show={show} centered size="md" onHide={handleClose}>
                <ProfileStep fromSettingsMenu={true} handleClose={handleClose} handleSave={handleSave} />
            </Modal>
        </>
    );
}
