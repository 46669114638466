import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Popover from "react-bootstrap/Popover";
import { toast } from "react-hot-toast";
import { createInnerMCQObject } from "../../../../utils/css2d/create-mcq-object";
import { Question } from "../../../../bit-components";

import question_model from "../../../../assets/models/question.glb";
import { createNetworkedEntity } from "../../../../utils/create-networked-entity";
import { Tooltip } from "react-tooltip";
import { useAuth0 } from "@auth0/auth0-react";

export default function EditMCQMenu(props) {
    let existingQuestions = [];

    props.bundle.mcqquestion_set.forEach(question => {
        let newfield = { question: question['question'],
                         mcqanswer_set: question['mcqanswer_set']
    
    };
        existingQuestions.push(newfield);
    })
    const [inputFields, setInputFields] = useState(existingQuestions);
    const { getAccessTokenSilently } = useAuth0();

    const addQuestion = () => {
        // Create the question on server object

        const patch_payload = {
            current_classroom: window.APP.classroom.id,
            mcqquestion_set: inputFields
        };

        fetch(process.env.REACT_APP_MM_API_URL + "assessments/mcq/" + props.bundle.id, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(patch_payload)
        })
            .then(resp => {
                console.log(resp);
                return resp.json();
            })
            .then(data => {
                console.log("Successfully changed question on server!");
                toast.success("Changes were saved.");             
                let toRemoveQuestions = JSON.parse(window.localStorage.getItem("questions"));

                if (Array.isArray(toRemoveQuestions)) {
                    const itemToRemove = props.bundle.id; 
                    toRemoveQuestions = toRemoveQuestions.filter(question => question !== itemToRemove);
                    window.localStorage.setItem("questions", JSON.stringify(toRemoveQuestions));
                }
                const question = APP.world.eid2obj.get(props.object.eid);

                const newContainer = createInnerMCQObject(data, props.object.eid)
                
                question.element.innerHTML = ""
                question.element.appendChild(newContainer);

                // TODO: CHeck that param.bundle from original inflator is same as data?
                Question.bundle[props.object.eid] = APP.getSid(data);

                window.APP.objectHelper.change(props.object.eid);
            });
    };

    const handleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index]["question"] = event.target.value;
        setInputFields(data);
    };

    const handleAnswerChange = (index, answerIndex, event) => {
        console.log("Got answer change on", index, answerIndex, event);
        let data = [...inputFields];
        console.log(data);
        data[index]["mcqanswer_set"][answerIndex]["answer"] = event.target.value;
        setInputFields(data);
    };

    const handleCorrectChange = (index, answerIndex, correct) => {
        let data = [...inputFields];
        data[index]["mcqanswer_set"][answerIndex]["correct"] = correct;
        setInputFields(data);
    };

    const addFields = () => {
        let newfield = {
            question: "Question",
            mcqanswer_set: [
                { answer: "Answer 1", correct: true },
                { answer: "Answer 2", correct: false },
                { answer: "Answer 3", correct: false },
                { answer: "Answer 4", correct: false }
            ]
        };
        setInputFields([...inputFields, newfield]);
    };

    const removeFields = index => {
        let data = [...inputFields];
        data.splice(index, 1);
        setInputFields(data);
    };

    const disabled = inputFields.length <= 0;

    return (
        <div>
            <div className="d-grid gap-2 d-md-block mb-3">
                <p className="fw-bold">Edit Questions</p>
            </div>
            <>
                <Row className="pb-1">
                    <Col>
                        {inputFields.map((input, index) => {
                            return (
                                <>
                                    <Row className="pb-1 mb-2" key={index}>
                                        <Col xs={10}>
                                            <textarea
                                                value={input.question}
                                                className="form-control"
                                                placeholder="What is your favorite color?"
                                                maxlength="440"
                                                type="text"
                                                onChange={event => handleFormChange(index, event)}
                                            ></textarea>
                                        </Col>
                                        <Col xs={2} className="d-flex align-items-center">
                                            <button
                                                className="btn btn-danger w-100 d-flex justify-content-center align-content-center"
                                                onClick={() => removeFields(index)}
                                            >
                                                <i className="bi bi-trash-fill"></i>
                                            </button>
                                        </Col>
                                    </Row>
                                    <Row className="border-bottom pb-3 pt-3 mb-4" key={index}>
                                        {input.mcqanswer_set.map((answer, answerIndex) => (
                                            <>
                                                <Col xs={6}>
                                                    <div className="input-group mb-3">
                                                        <div
                                                            className={
                                                                answer.correct
                                                                    ? "bg-success input-group-text"
                                                                    : "bg-danger input-group-text"
                                                            }
                                                        >
                                                            <input
                                                                className="btn-success"
                                                                type="checkbox"
                                                                checked={answer.correct}
                                                                onClick={() =>
                                                                    handleCorrectChange(
                                                                        index,
                                                                        answerIndex,
                                                                        !answer.correct
                                                                    )
                                                                }
                                                            ></input>
                                                        </div>
                                                        <textarea
                                                            value={answer.answer}
                                                            className="form-control"
                                                            placeholder="What is your favorite color?"
                                                            type="text"
                                                            onChange={event =>
                                                                handleAnswerChange(index, answerIndex, event)
                                                            }
                                                        ></textarea>
                                                    </div>
                                                </Col>
                                            </>
                                        ))}
                                    </Row>
                                </>
                            );
                        })}
                    </Col>
                </Row>
                <Row className="pb-1 pt-1">
                    <Col xs={12}>
                        <button
                            className="btn btn-primary w-100 d-flex justify-content-center align-content-center"
                            onClick={addFields}
                        >
                            + Add Additional Question
                        </button>
                    </Col>
                </Row>
                <Row className="d-grid g-1 pt-3 mb-3 border-top">
                    <Button variant="success" type="button" disabled={disabled} onClick={() => addQuestion()}>
                        Save
                    </Button>
                    <small className="mb-0 p-0 mt-1 fw-bold">Note: students need to refresh to see changes</small>
                </Row>
            </>
        </div>
    );
}
