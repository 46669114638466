import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

export default function ResetViewOnboardingButton(props) {
    return (
        <Dropdown.Item draggable={false} onClick={() => props.resetOnboarding()}>
            <i className="bi bi-arrow-counterclockwise"></i>
            <span className="ms-2 mb-0">Reset Tutorials</span>
        </Dropdown.Item>
    );
}
