/** @jsx createElementEntity */
import { createElementEntity, createRef } from "../utils/jsx-entity";
import { renderAsEntity } from "./jsx-entity";
import { HubsWorld } from "../app";
import { Button3D } from "../prefabs/button3D";

export function* loadAudio(world: HubsWorld, url: string) {
    const playRef = createRef();
    const pauseRef = createRef();
    const stopRef = createRef();

    if (window.APP?.scene?.audioListener) {
        // Create audio element
        const audioEl = document.createElement("audio");

        return renderAsEntity(
            world,
            <entity name="Audio" networked networkedAudio audio={{ url: url, autoPlay: false, audioEl: audioEl }}>
                <Button3D
                    ref={playRef}
                    type={0}
                    scale={[0.4, 0.4, 0.4]}
                    position={[0.2, 0.2, 0.01]}
                    width={0.6}
                    userData={{ way: "play" }}
                    height={0.4}
                    text={"Play"}
                />
                <Button3D
                    ref={pauseRef}
                    type={0}
                    scale={[0.4, 0.4, 0.4]}
                    position={[0.2, 0.0, 0.01]}
                    width={0.6}
                    height={0.4}
                    userData={{ way: "pause" }}
                    text={"Pause"}
                />
                <Button3D
                    ref={stopRef}
                    type={0}
                    scale={[0.4, 0.4, 0.4]}
                    position={[0.2, -0.2, 0.01]}
                    width={0.6}
                    userData={{ way: "stop" }}
                    height={0.4}
                    text={"Stop"}
                />
            </entity>
        );
    } else {
        return renderAsEntity(
            world,
            <entity name="Audio" networked networkedAudio grabbable={{ cursor: true, hand: false }}></entity>
        );
    }
}
