import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Tooltip } from "react-tooltip";
import { createNetworkedEntity } from "../../../../utils/create-networked-entity";

import alloy from "../../../../assets/agents/alloy.mp3";
import shimmer from "../../../../assets/agents/shimmer.mp3";
import sage from "../../../../assets/agents/sage.mp3";
import coral from "../../../../assets/agents/coral.mp3";
import ash from "../../../../assets/agents/ash.mp3";
import echo from "../../../../assets/agents/echo.mp3";
// import nova from "../../../../assets/agents/nova.mp3"; # Not implemented in LiveKit yet.
// import onyx from "../../../../assets/agents/onyx.mp3"; # Not implemented in LiveKit yet.
// import fable from "../../../../assets/agents/fable.mp3"; # Not implemented in LiveKit yet.
//

import ericImage from "../../../../assets/models/eric.png";
import duckImage from "../../../../assets/models/duck.png";
import girl1Image from "../../../../assets/models/girl1.png";
import girl2Image from "../../../../assets/models/girl2.png";
import girl3Image from "../../../../assets/models/girl3.png";
import girl4Image from "../../../../assets/models/girl4.png";
import girl5Image from "../../../../assets/models/girl5.png";
import boy1Image from "../../../../assets/models/boy1.png";
import boy2Image from "../../../../assets/models/boy2.png";
import boy3Image from "../../../../assets/models/boy3.png";

const VOICE_LIST = {
    alloy: {
        name: "Alloy",
        prompt: "placeholder"
    },
    shimmer: {
        name: "Shimmer",
        prompt: "placeholder"
    },
    sage: {
        name: "Sage",
        prompt: "placeholder"
    },
    coral: {
        name: "Coral",
        prompt: "placeholder"
    },
    ash: {
        name: "Ash",
        prompt: "placeholder"
    },
    echo: {
        name: "Echo",
        prompt: "placeholder"
    }
};

const AVATAR_LIST = {
    eric: ericImage,
    duck: duckImage,
    girl1: girl1Image,
    girl2: girl2Image,
    girl3: girl3Image,
    girl4: girl4Image,
    girl5: girl5Image,
    boy1: boy1Image,
    boy2: boy2Image,
    boy3: boy3Image
};

export default function CreateNPCMenu(props) {
    const [model, setModel] = useState("eric"); // Default to 'eric'
    const [prompt, setPrompt] = useState("You are a helpful teacher.");
    const [voice, setVoice] = useState("alloy");
    const [audio, setAudio] = useState(null);

    // Map voices to their respective audio files
    const voiceAudioMap = {
        alloy,
        shimmer,
        sage,
        coral,
        ash,
        echo
    };

    const handlePlaySample = () => {
        if (audio) {
            audio.pause(); // Stop any currently playing audio
        }

        const audioFile = new Audio(voiceAudioMap[voice]);
        setAudio(audioFile);
        audioFile.play();
    };

    const handleAvatarClick = modelName => {
        setModel(modelName); // Set the selected model
    };

    const handleSubmit = () => {
        const eid = createNetworkedEntity(APP.world, "npc", {
            prompt,
            voice,
            model
        });

        const avatarPov = document.querySelector("#avatar-pov-node").object3D;
        const obj = APP.world.eid2obj.get(eid);
        obj.position.copy(avatarPov.localToWorld(new THREE.Vector3(0, 0, -1.5)));
        obj.scale.set(1.7, 1.7, 1.7);
        obj.lookAt(avatarPov.getWorldPosition(new THREE.Vector3()));

        // props.onFinish();

        console.log(
            "Posting the following NPC",
            null,
            "npc",
            JSON.stringify({ object_id: eid, agent_name: "npc-agent", systemPrompt: prompt, voice: voice }),
            "npc"
        );

        setTimeout(() => {
            window.APP.objectHelper.save(
                eid, // EID
                null, // id
                "npc", // filename
                JSON.stringify({ // src
                    // question_id
                    object_id: eid,
                    agent_name: "npc-agent",
                    prompt: prompt,
                    voice: voice,
                    model: model
                }),
                "npc"
            );
        }, 1000);
    };

    return (
        <div className="p-3">
            <Tooltip id="addMediaTooltip" />
            <Button
                data-tooltip-id="addMediaTooltip"
                data-tooltip-content="Back to list"
                variant="light"
                onClick={() => props.onBack()}
            >
                <i className="bi bi-arrow-left"></i>
            </Button>
            <h5 className="mb-3">Create NPC</h5>

            <div className="mb-3">
                <label className="form-label">Voice</label>
                <select value={voice} onChange={e => setVoice(e.target.value)} className="form-select">
                    {Object.entries(VOICE_LIST).map(([key, template]) => (
                        <option key={key} value={key}>
                            {template.name}
                        </option>
                    ))}
                </select>
            </div>
            <Button onClick={handlePlaySample} variant="primary" className="mb-3">
                Play Sample
            </Button>

            <div className="mb-3">
                <label className="form-label">Custom System Prompt</label>
                <textarea
                    value={prompt}
                    onChange={e => setPrompt(e.target.value)}
                    className="form-control"
                    rows={4}
                    placeholder="Enter AI prompt for the NPC..."
                />
            </div>

            {/* Avatar Selector Grid */}
            <div className="mb-3">
                <label className="form-label">Select Avatar</label>
                <Row>
                    {Object.keys(AVATAR_LIST).map(modelName => (
                        <Col key={modelName} xs={6} sm={4} md={3} lg={2}>
                            <Card
                                className="avatar-card"
                                style={{
                                    cursor: "pointer",
                                    border: model === modelName ? "2px solid #007bff" : "none" // Highlight selected avatar
                                }}
                                onClick={() => handleAvatarClick(modelName)} // Select model on click
                            >
                                <Card.Img variant="top" src={AVATAR_LIST[modelName]} />
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>

            <Button onClick={handleSubmit} variant="success" className="w-100">
                Create NPC
            </Button>
        </div>
    );
}
